import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginPPVRequestInterface, RequestLogoutPPVInterface, ResponseLogoutPPVInterface } from '../interfaces/ppv.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthPpvService {
  url: string;
  constructor(private http: HttpClient) {
   this.url = environment.url_base;
  }

  loginPPV(request: LoginPPVRequestInterface): Observable<any> {
    return this.http.post<any>(this.url+"/PPVoluntaria/sesion/loginPpv", JSON.stringify(request),
      {headers: {"Content-Type": "application/json", "Accept": "application/json"}} );
  }

  logoutPPV(request: RequestLogoutPPVInterface): Observable<ResponseLogoutPPVInterface> {
    return this.http.post<any>(this.url+'/PPVoluntaria/sesion/logout', request);
  }

}
